import React, { useState,useEffect } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

import NftCard from '../../components/Modals/NftCard'
import MintNft from '../../components/Nft/MintNft'
import { Tab, Nav } from 'react-bootstrap';

import {loadMyNftsData,loadAllNftsData} from "../../services/actions"

const Gallery = (props) => {
  const {user,nftInfo,myNfts,allNfts} = props;
  const [showNftCard, setShowNftCard] = useState(false);
  const [currentTab, setCurrentTab] = useState('your_nfts');

  const [myFrom, setMyFrom] = useState(0);
  const [allFrom, setAllFrom] = useState(0);


  useEffect(()=>{
    loadNfts(currentTab);
  },[]);

  const changeTab=(val)=>{
    setCurrentTab(val);
    loadNfts(val);
  }

  const loadNfts =(tab_name)=>{
    if(tab_name == 'your_nfts'){
      if(myNfts.type=="" || myNfts.type=="success"){
        props.loadMyNftsData(user.data.eth_address,myFrom);
        setMyFrom(myFrom+1)
      }
    }else{
      if(allNfts.type=="" || allNfts.type=="success"){
        props.loadAllNftsData(allFrom);
        setAllFrom(allFrom+1);
      }
    }
  }

  return (
    <>
      {showNftCard ?
        <>
          <NftCard show={showNftCard!=false} nft={showNftCard} handleCloseAlert={() => { setShowNftCard(false); }} />
        </>
        : ""}


      <section className="mint_section" style={{ height: '100%' }}>
        <div className="container content-space-2">
          <div className="row justify-content-center ">
            <div className="col-lg-5 d-flex align-items-center">
              <div className="content">
                <div className="content_title">PFP Gallery</div>
                <p className=" content_text" style={{ paddingRight: "3rem" }}>See all the WeiBetterPFPs you've created and also see what others are creating! <br/><br/>
                With Wei Better PFP (wei pronounced "way"), you can turn your profile picture into an NFT. Right now, this can be used by Twitter Blue Members, however, it is our understanding many other platforms will have similar features soon. With your Wei Better PFP, you'll be ready to show the world who you are and prove connected accounts across multiple platforms.

          </p>
              </div>
            </div>
            <div className="col-lg-6 col-sm-6 d-none d-md-block">
            <div className="gallery_mobile_image">
              <div className="row">
                <div className="col-8">
                  <div className="card border-0  d-flex justify-content-center">
                    <img className="img-responsive img_mask" src="./assets/images/social-group-blockchain.png" />
                  </div>
                </div>
           
              </div>
            </div>
            </div>

          </div>
        </div>
      </section>


      <section className="py-5">
        <div className="container">
          <Tab.Container id="left-tabs-example" activeKey={currentTab} onSelect={changeTab}>
            <div className="row">
              <div className="col d-flex justify-content-center" >

                <Nav variant="pills" className="nfts_tab">
                  <Nav.Item>
                    <Nav.Link eventKey="all_nfts">All PFPs ({nftInfo.totalSupply})</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="your_nfts">Your PFPs ({nftInfo.myNftsCount})</Nav.Link>
                  </Nav.Item>
                </Nav>


              </div>
            </div>

            <div>
              <Tab.Content>
                <Tab.Pane eventKey="all_nfts">
                <div className="row py-3">
                    <MintNft nfts={allNfts.data} viewNft={(v)=>setShowNftCard(v)}/>
                    {allNfts.type=='new_request'&&
                    <div className="d-flex justify-content-center align-self-center">
                      <h3  className="pt-5"> Loading...</h3>
                      <br/>
                      <br/>
                     </div>
                    }
                  {((allNfts.type=='success' || allNfts.type=='request') && allNfts.data.length < nftInfo.totalSupply) &&
                      <div className="d-flex justify-content-center align-self-center pt-5">
                          <button onClick={()=>loadNfts(currentTab)} className="btn btn-primary mint_btn align-items-center" disabled={allNfts.type=='request'}> {allNfts.type=='request' ? "Loading..." : "Load More"}</button>
                      </div>
                    }
                </div>
                </Tab.Pane>
                <Tab.Pane eventKey="your_nfts">
                  <div className="row py-3">
                    <MintNft nfts={myNfts.data} viewNft={(v)=>setShowNftCard(v)}/>
                    {myNfts.type=='new_request'&&
                    <div className="d-flex justify-content-center align-self-center">
                      <h3  className="pt-5"> Loading...</h3>
                      <br/>
                      <br/>
                     </div>
                    }
                    {(myNfts.type=='success' && myNfts.data.length == 0)&&
                    <div className="d-flex justify-content-center align-self-center">
                      <h3  className="pt-5">No PFPs? No worries. <Link to="/" className="btn btn-primary mint_btn align-items-center py-2" style={{height:"45px"}}>  Mint PFP now!</Link></h3>
                      <br/>
                      <br/>
                     </div>
                    }

                  {((myNfts.type=='success' || myNfts.type=='request') && myNfts.data.length < nftInfo.myNftsCount) &&
                      <div className="d-flex justify-content-center align-self-center pt-5">
                          <button onClick={()=>loadNfts(currentTab)} className="btn btn-primary mint_btn align-items-center" disabled={myNfts.type=='request'}> {myNfts.type=='request' ? "Loading..." : "Load More"}</button>
                      </div>
                    }


                  </div>
                </Tab.Pane>
              </Tab.Content>

            </div>

          </Tab.Container>
        </div>
      </section>



    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  nftInfo: state.nftInfo,
  myNfts: state.myNfts,
  allNfts: state.allNfts,
})

const mapDispatchToProps = {
  loadMyNftsData,loadAllNftsData
}
export default connect(mapStateToProps, mapDispatchToProps)(Gallery)

