import React from 'react'
import Modal from 'react-bootstrap/Modal'

import * as constants from "../../services/constants"

const NftCard = (props) => {
  const {nft}=props;

  let data = [
    {
      attr_type: "Facebook",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Facebook").value
    },
    {
      attr_type: "Instagram",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Instagram").value
    },
    {
      attr_type: "Twitter",
      attr_value: nft.attributes.find((v)=>v.trait_type=="Twitter").value
    }
  ]

  let property_data = data.map((key, index) => {
    return (
      <div className="row" key={key.attr_type}>
        <div className="col-lg-4"><h6 className="attr_type">{key.attr_type} </h6></div>
        <div className="col-lg-8"><h6 className="attr_value">{key.attr_value} </h6></div>
      </div>
    )
  })

  const shareTwitter = ()=>{
    const messages = ['Look at my WeiBetterPFP! Get yours at www.weibetterpfp.io !!',"new PFP, who 'dis? www.weibetterpfp.io","PFP? WeiBetterPFP! www.weibetterpfp.io","❤️ WeiBetterPFP! www.weibetterpfp.io"]
    let message = messages[Math.floor(Math.random() * messages.length)]

    window.open("https://twitter.com/share?text="+message+"&url="+constants.DOMAIN_NAME+"pfp/"+nft.id+"&hashtags=WeiBetterPFP!,PFP");
  }
  

  return (
    <>
      <Modal show={props.show} onHide={props.handleCloseAlert} backdrop={true} backdropClassName="modal-backdrop foo-modal-backdrop in" dialogClassName="modal-alert">

        <Modal.Body>
          <div className="card  mx-auto border-0">
            <img src={nft.image} className="img-fluid"/>
            <div className="card-body pt-4 px-2 ">
              <div className=" text-center border-bottom">
                <h5 className="card-title nft_token" style={{ fontSize: "22px" }}>{nft.name}</h5>
                {/* <h6 className="font-16 nft_token pb-3">{nft.attributes.find((v)=>v.trait_type=="Twitter").value}</h6> */}
              </div>

              <div className="row py-3">

                {property_data}

                <br/>
                <br/>
                <div className="d-flex justify-content-center align-self-center">
                 <button
                      className="btn btn-light bg-transparent  w-100 align-items-center share_btn  px-3 py-2 rounded-0 border  border-secondary" style={{height:"51.31px"}} onClick={shareTwitter}>Share
                      On Twitter <i className="fa fa-arrow-right"></i></button>
                </div>
              </div>
              <hr />
              <button className="btn btn-light w-100 rounded-0 border-0 bg-transparent trait_font font-16" style={{
                textDecorationLine: " underline",
                textTransform: "capitalize"
              }}
              onClick={()=>{props.handleCloseAlert()}}
              >close</button>
            </div>
          </div>

        </Modal.Body>

      </Modal>

    </>
  )
}

export default NftCard
