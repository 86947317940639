import React, { useState, useEffect,useRef } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from "react-redux";

import { userLogin,mintNftWithEth,resetMint } from "../../services/actions"

import * as constants from "../../services/constants"

export const Home = (props) => {
  const [mintProcess, setMintProcess] = useState(false);
  const [mintNumber, setMintNumber] = useState(1);
  const [nftInfoObj, setNFTInfoObj] = useState({name:"",details:"",insta_handle:"",fb_handle:"",tw_handle:"",pic:""});
  const fileRef = useRef(null);
  const imagePreview = useRef(null);
  const [error, setError] = useState("");
  const { user,nftInfo } = props;

  const price = nftInfo.price/(10**18);

  useEffect(() => {
    props.resetMint();
  }, [])

  const setValue=(key,value)=>{
    if(mintProcess){
      return
    }
    let info = {...nftInfoObj};

    if(typeof value == 'string' && info[key] ==""){
      value = value.trim();
    }
    info[key] = value;
    setNFTInfoObj(info);
  }

  const setMintCount = (e) => {
    let num = e.target.value;
    if (num > 0) {
      setError("");
      setMintNumber(num);
    }
  }

  const mintTokenBtn = (type)=>{
    var formData = new FormData();
    formData.append('name', nftInfoObj['name'].trim());
    formData.append('details', nftInfoObj['details'].trim());
    formData.append('insta_handle', nftInfoObj['insta_handle'].trim());
    formData.append('fb_handle', nftInfoObj['fb_handle'].trim());
    formData.append('tw_handle', nftInfoObj['tw_handle'].trim());
    formData.append('pic', nftInfoObj['pic']); 
    formData.append('eth_address', user.data.eth_address); 
    setError("");
    setMintProcess(true)
      props.mintNftWithEth(formData,user.data.eth_address,(data)=>{
        setMintProcess(false)
        if(data.type=="success"){
          props.history.push({
            pathname: '/mint',
            search: '',
            params: { mintData: data }
          })
        }else{
          setError(data.message);
        }
      })
  }

  const setNftImage= (e)=>{
    console.log(e.target);
    let input = fileRef.current;
    if (input.files && input.files[0]) {
      setValue('pic',input.files[0]);
      var reader = new FileReader();
      reader.onload = function (e) {
          imagePreview.current.src =e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
    }else{
      imagePreview.current.src ="./assets/images/social-avatar.png";
      setValue('pic',"")
    }
}

  const openFileUPload =()=>{
    if(mintProcess){
      return
    }
    console.log("fileRef",fileRef.current);
    fileRef.current.click();
  }

  return (
    <>
      <section className="main-section">
        <div className="container content-space-2">
          <div className="row">
          {user.login ?
            <div className="col-lg-5 text-center"  style={{cursor:"pointer"}}>
              <img src="./assets/images/social-avatar.png" className="nft_img img_mask"  width="300" ref={imagePreview} onClick={openFileUPload}/>
              <p>
                <br/>
                <input type="file" name="nft_image" id="nft_image" className='d-none' ref={fileRef} onChange={setNftImage} accept="image/*"/>
                <button className='btn btn-primary  ' style={{height:"40px"}} onClick={openFileUPload}>Select</button>
                <br/>
                Upload Image Here* <br/><br/>
                <small><i> PS: We recommend a minimum image size of 400px by 400px.</i></small>
              </p>
            </div>
            :
              <div className="col-lg-5 text-center">
                <img src="./assets/images/social-avatar.png" className="nft_img img_mask"  width="300"/>
                <p>
                  <br/>
                  <br/>
                   <h5>Connect your wallet and make your <br/> PFP!</h5>
                </p>
              </div>
            }
            <div className="col-lg-6">
              <div className="content ">
            
                <h2 className="content_title">Generate Wei Better PFP</h2>
                <p className="  content_text">With Wei Better PFP (wei pronounced "way"), you can turn your profile picture into an NFT. Right now, this can be used by Twitter Blue Members, however, it is our understanding many other platforms will have similar features soon. With your Wei Better PFP, you'll be ready to show the world who you are and prove connected accounts across multiple platforms. 
                
                </p>

                <div className="row mint_form">

                  {user.login ?
                    <>
                      <h3 className="content_sub_title pb-2"> PFP Details</h3>
                      <p>With every NFT generated, there is data that is stored along with the NFT. Fill in as little or as much as you'd like so others can see the social profiles linked to your NFT PFP!</p>
                     
                      <div className="col-md-12 pl-0">
                         <div id="error_explaintion"></div>
                          <div className="form-group mb-3">
                            <label htmlFor="name" className="form-label"><b>Name</b>*  </label>
                            <input type="text" className="form-control" id="name" value={nftInfoObj['name']} onChange={(e)=>{setValue('name',e.target.value);}} placeholder='Wei Wei'/>
                          </div>
                          <div className="form-group mb-3">
                            <label htmlFor="details" className="form-label"><b>Details</b></label>
                            <textarea className="form-control" id="description" value={nftInfoObj['details']} onChange={(e)=>{setValue('details',e.target.value);}} placeholder='What do you want people to know about your PFP?' />
                          </div>

                          <label htmlFor="details" className="form-label"><b>Social Profiles</b></label>
                          <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><i className="fab fa-facebook"></i></span>
                            <input type="text" className="form-control" placeholder="@WeiBetterPFP" aria-label="facebook" name="facebook" value={nftInfoObj['fb_handle']} onChange={(e)=>{setValue('fb_handle',e.target.value);}}/>
                          </div>
                          <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><i className="fab fa-instagram"></i></span>
                            <input type="text" className="form-control" placeholder="@WeiBetterPFP" aria-label="instagram" name="instagram" value={nftInfoObj['insta_handle']} onChange={(e)=>{setValue('insta_handle',e.target.value);}}/>
                          </div>
                          <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1"><i className="fab fa-twitter"></i></span>
                            <input type="text" className="form-control" placeholder="@WeiBetterPFP" aria-label="twitter" name="twitter" value={nftInfoObj['tw_handle']} onChange={(e)=>{setValue('tw_handle',e.target.value);}}/>
                          </div>
                          <p className='text-center'><i>PS: You can update this data above later on, no gas fee required!</i></p>

                       </div>
                      <div className='d-flex justify-content-center'>
                          <div className="col-md-9 ">
                            <button className="btn btn-primary mint_btn w-100 align-items-center" onClick={()=>{mintTokenBtn('eth')}} disabled={(nftInfoObj['name']=="" || nftInfoObj['pic'] =="") || mintProcess}>{mintProcess ? "Minting..." : <>Mint PFP <i
                              className="fa fa-arrow-right"></i></>}</button>

                      {error != "" &&
                            <p className="err_msg text-center"> <br/>{error}</p>
                          }
                          </div>
                       
                      </div>
                   
                   
                      
                    </>
                    :
                    <>
                     <div className="d-flex justify-content-center">
                        <button className="btn btn-primary  mx-auto col-lg-9 col-md-8 col-sm-12" onClick={() => { props.userLogin() }}><img src="./assets/images/metamask-fox-logo.svg" width="" /> Connect With Metamask</button>
                       
                      </div>
                      {user.message != "" &&
                          <p className="err_msg ms-3 pt-3 text-center">{user.message}</p>
                        }
                    </>
                  }



                </div>
              </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

Home.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.user,
  nftInfo: state.nftInfo,
  mintInfo: state.mintInfo,
})

const mapDispatchToProps = {
  userLogin,mintNftWithEth,resetMint
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
