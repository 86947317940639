import React, {Suspense,StrictMode} from "react";
import { Provider } from "react-redux";
import configureStore from "./services/configureStore";

import Routes from "./routes"

import { stopReportingRuntimeErrors } from "react-error-overlay";

// import all scss files from styles folder
import "./styles/App.css"

const store = configureStore();
// import ReactGA from "react-ga";

if (process.env.REACT_APP_NODE_ENV =="production" ) {
  // stopReportingRuntimeErrors(); // disables error overlays
}

// for https redirect
if (process.env.REACT_APP_NODE_ENV =="production" && window.location.protocol !== 'https:') {
  window.location.replace(`https:${window.location.href.substring(window.location.protocol.length)}`);
}

function App() {
  return (
    <StrictMode>
      <Provider store={store}>
        <Suspense fallback={null}>
            <Routes/>
        </Suspense>
      </Provider>
    </StrictMode>
  );
}

export default App;
