import React from 'react'

const MintNft = (props) => {
  const {nfts,viewNft} = props;

  let viewData=nfts.map((nft,key)=>{
    return <div className="col-lg-3 col-md-6 col-sm-12" key={key} onClick={()=>{viewNft(nft)}} style={{cursor:"pointer"}}>
            <div className="card  mx-auto p-2 rounded-0 my-2" >
                <img src={nft.image}  className="img-fluid"/>
            <div className="card-body p-4 ">
              <div className=" text-center">
                <h5 className="card-title nft_token" style={{ fontSize: "16px" }}>{nft.name}</h5>
                <h5 className="card-title nft_token">{nft.attributes.find((v)=>v.trait_type=="Twitter").value}</h5>
              </div>
            </div>
          </div>
        </div>
  })

  return (
    <>
      {viewData}
    </>
  )
}

export default MintNft
