import React from "react";
import { Route, BrowserRouter, Redirect } from "react-router-dom";

import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { OuterLayout, InnerLayout } from "../layouts"
import Home from "../pages/Home"
import Mint from "../pages/Mint";
import Gallery from "../pages/Gallery";
// import Error from "../pages/Error"


function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props} login={rest.login}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

export const Routes = (props) => {
  const { user } = props
  return (
    <BrowserRouter>
      {user.login ?
        <>
          <RouteWrapper exact path="/" component={Home} layout={OuterLayout} />
          <RouteWrapper exact path="/gallery" component={Gallery} layout={OuterLayout} />
          <RouteWrapper exact path="/mint" component={Mint} layout={OuterLayout} />
          <Redirect to="/" />
        </>
        :
        <>
          <RouteWrapper exact path="/" component={Home} layout={OuterLayout} />
          <RouteWrapper exact path="/gallery" component={Home} layout={OuterLayout} />
          <RouteWrapper exact path="/mint" component={Home} layout={OuterLayout} />
          <Redirect to="/" />
        </>

      }

    </BrowserRouter>
  )
}

Routes.propTypes = {
  user: PropTypes.object
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
