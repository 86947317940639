import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Header from "../components/Header"
import Footer from "../components/Footer"

// import './App.css';
export function OuterLayout(props) {
  return (
    <>
      <Header />
      {props.children}
    </>
  )
}

OuterLayout.propTypes = {

}




