import React,{useEffect,useState} from 'react'
import { Link,withRouter } from 'react-router-dom'

import PropTypes from 'prop-types'
import { connect } from "react-redux";

import {getTokenInfo} from "../../utils/ethereum";

import * as constants from "../../services/constants"

const Mint = (props) => {
  const {mintInfo}=props;
  //finalTx tokenIds
  const [tokenInfo,setTokenInfo]=useState([]);
  const [showToken,setShowToken]=useState(false);
  useEffect(async()=>{
    if(mintInfo.type =='success' && mintInfo.data.tokenIds.length>0){
      let tokenData = await getTokenInfo(mintInfo.data.tokenIds);
      setTokenInfo(tokenData);
      if(tokenData.length > 0){
        setShowToken(0)
      }
    }else{
      props.history.push({ pathname: '/'});
    }
  },[mintInfo.data.tokenIds])

  const shareTwitter = ()=>{
    const messages = ['Look at my WeiBetterPFP! Get yours at www.weibetterpfp.io !!',"new PFP, who 'dis? www.weibetterpfp.io","PFP? WeiBetterPFP! www.weibetterpfp.io","❤️ WeiBetterPFP! www.weibetterpfp.io"]
    let message = messages[Math.floor(Math.random() * messages.length)]
    window.open("https://twitter.com/share?text="+message+"&url="+constants.DOMAIN_NAME+"pfp/"+tokenInfo[showToken].id+"&hashtags=weibetterpfp,PFP");

  }
  
  return (
    <>
      <section className="mint_section">
        <div className="container content-space-2">
          <div className="row align-items-center">
            <div className="col-lg-6 text-center">
              {showToken===false ?
              <div className="card w-50 mx-auto text-start">
                <img src="./assets/images/no-image.svg" key={"no-image"} height="316"/>
                <div className="card-body p-4 ">
                  <h5 className="card-title text-center">Loading...</h5>
                  <a className="green_text text-start"></a>
                </div>
              </div>
              :
              <div className="card w-50 mx-auto text-start">
                <img src={tokenInfo[showToken].image} key={showToken} height="316"/>
                <div className="card-body p-4 ">
                  <h5 className="card-title text-start">PFP #{tokenInfo[showToken].id}</h5>
                  <Link to="/gallery" className="green_text text-start">View PFP Gallery</Link>
                </div>
              </div>
             }
            </div>
            <div className="col-lg-6">
              <div className="content">
                <h2 className="font-72">Welcome To Wei Better PFP</h2>
                <p className="w-75 content_text" style={{ paddingRight: "1rem" }}>Congratulations! You now have a PFP NFT! You can view your PFP by clicking "My PFP" above and we recommend sharing your new PFP on Twitter.</p>

                <div className="row ">

                  {/* <div className="col-lg-4 pl-0">
                    <Link to="/" className="btn btn-light w-100 align-items-center px-3 py-59 rounded-0 border  border-secondary mint_bold_btn" >Mint
                      Another </Link>
                  </div> */}
                  <div className="col-lg-4">
                    <button
                      className="btn btn-light bg-transparent  w-100 align-items-center share_btn  px-3 py-2 rounded-0 border  border-secondary" style={{height:"51.31px"}} onClick={shareTwitter}>Share
                      On Twitter <i className="fa fa-arrow-right"></i></button>
                  </div>
                </div>
                <br/>
                <p className="w-75 content_text" style={{ paddingRight: "1rem" }}>To learn more about NFT PFPs, <a href='https://help.twitter.com/en/using-twitter/twitter-blue-fragments-folder/nft' target='_blank'>read this article from Twitter.</a> </p>
              </div>
            </div>


          </div>
          <div className="row p-5 ms-2">
            {tokenInfo.length > 1 &&
              <div className="col-lg-2 text-end">
                <button className="btn btn-link" disabled={showToken==0} onClick={()=>{setShowToken(showToken-1)}}><i className="far fa-arrow-left" disabled={showToken==0}></i></button>
                <button className="btn btn-link" disabled={showToken==(tokenInfo.length-1)} onClick={()=>{setShowToken(showToken+1)}}><i className="far fa-arrow-right ps-2"></i></button>
              </div>
            }
          </div>
        </div>
      </section>
    </>
  )
}


const mapStateToProps = (state) => ({
  user: state.user,
  mintInfo: state.mintInfo,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Mint))