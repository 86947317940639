import { combineReducers } from 'redux';

import {userReducer} from './user';
import {nftInfoReducer,mintInfoReducer,myNftsReducer,allNftsReducer} from './list';

const rootReducer = combineReducers({
    user:userReducer,
    nftInfo:nftInfoReducer,
    mintInfo:mintInfoReducer,
    myNfts:myNftsReducer,
    allNfts:allNftsReducer,
});

export default rootReducer;