export function  nftInfoReducer(state = {maxSupply:"",totalSupply:"",leftSupply:"",price:"",myNftsCount:"",myTokenBalance:""}, action){
  switch (action.type) {
    case "NFT_INFO":
      return action.data
    case "NFT_INFO_DEFAULT":
      return {maxSupply:"",totalSupply:"",leftSupply:"",price:"",myNftsCount:"",myTokenBalance:""}
  }
  return state;
}

export function  mintInfoReducer(state = {type:"",data:{},message:""}, action){
  switch (action.type) {
    case "MY_NFT_MINT_RESET":
      return {type:"",data:{},message:""}
    case "MY_NFT_MINT_SUCCESS":
      return {type:"success",data:action.data,message:action.message}
    case "MY_NFT_MINT_ERROR":
      return {type:"error",data:action.data,message:action.message}
  }
  return state;
}

export function  myNftsReducer(state = {type:"",data:[]}, action){
  let data;
  switch (action.type) {
    case "MY_NFTS_RESET":
      return {type:"new_request",data:[]}
    case "MY_NFTS_PENDING":
      data = {...state};
      data.type ='request';
      return data
    case "MY_NFTS_SUCCESS":
      let oldData = [...state.data];
      for(let i=0;i<action.data.length;i++){
        let flag = true;
        for(let j=0;j<oldData.length;j++){
          if(oldData[j].id == action.data[i].id){
            flag=false;
            break;
          }
        }
        if(flag){
          oldData.push(action.data[i])
        }
      }
      console.log("final oldData",oldData);
      return {type:"success",data:oldData}
  }
  return state;
}

export function  allNftsReducer(state = {type:"",data:[]}, action){
  let data;
  switch (action.type) {
    case "ALL_NFTS_RESET":
      return {type:"new_request",data:[]}
    case "ALL_NFTS_PENDING":
      data = {...state};
      data.type ='request';
      return data
    case "ALL_NFTS_SUCCESS":
      let oldData = [...state.data];
      for(let i=0;i<action.data.length;i++){
        let flag = true;
        for(let j=0;j<oldData.length;j++){
          if(oldData[j].id == action.data[i].id){
            flag=false;
            break;
          }
        }
        if(flag){
          oldData.push(action.data[i])
        }
      }
      return {type:"success",data:oldData}
  }
  return state;
}
